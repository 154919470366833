// extracted by mini-css-extract-plugin
export var MsoFootnoteReference = "section-module--MsoFootnoteReference--55914";
export var MsoFootnoteText = "section-module--MsoFootnoteText--cb0b6";
export var categoriesSection = "section-module--categoriesSection--760be";
export var categoriesSectionHeader = "section-module--categoriesSectionHeader--a42c2";
export var categoryActive = "section-module--categoryActive--c9f32";
export var collectionsCategory = "section-module--collectionsCategory--e47a9";
export var collectionsColumn = "section-module--collectionsColumn--32a7c";
export var featuresCategory = "section-module--featuresCategory--eb5a8";
export var inlineGatsbyImageWrapper = "section-module--inline-gatsby-image-wrapper--892ea";
export var pictorialsCategory = "section-module--pictorialsCategory--67a8f";
export var recommendedSection = "section-module--recommendedSection--515fc";
export var recommendedSectionHeader = "section-module--recommendedSectionHeader--71a6b";
export var sectionTitle = "section-module--sectionTitle--2ba83";
export var wpCaption = "section-module--wp-caption--faa58";