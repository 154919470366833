// extracted by mini-css-extract-plugin
export var MsoFootnoteReference = "index-module--MsoFootnoteReference--534cd";
export var MsoFootnoteText = "index-module--MsoFootnoteText--92ad9";
export var carouselCell = "index-module--carousel-cell--a6398";
export var categoriesLists = "index-module--categoriesLists--3b949";
export var categoriesSection = "index-module--categoriesSection--237e5";
export var categoriesSectionHeader = "index-module--categoriesSectionHeader--8de48";
export var categoryActive = "index-module--categoryActive--6b18a";
export var collectionsArticle = "index-module--collectionsArticle--721f9";
export var collectionsCategory = "index-module--collectionsCategory--01f74";
export var collectionsColumn = "index-module--collectionsColumn--e3106";
export var features = "index-module--features--3134d";
export var featuresCategory = "index-module--featuresCategory--7d845";
export var inlineGatsbyImageWrapper = "index-module--inline-gatsby-image-wrapper--1f65b";
export var moreButton = "index-module--moreButton--efa8a";
export var pictorialsCategory = "index-module--pictorialsCategory--95667";
export var recommended = "index-module--recommended--d99a1";
export var recommendedSection = "index-module--recommendedSection--ac4a0";
export var recommendedSectionHeader = "index-module--recommendedSectionHeader--6ace2";
export var sectionTitle = "index-module--sectionTitle--18ccf";
export var testDiv = "index-module--testDiv--375cc";
export var wpCaption = "index-module--wp-caption--dd373";